import {Component, OnInit} from '@angular/core';
import {UserModel} from "../../../models/user.model";
import {AdminClientService} from "../../../services/admin/admin-client.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import * as _ from "lodash";
import {MatDialog} from "@angular/material/dialog";
import {ChangePasswordDialogComponent} from "../../../dialog/change-password/change-password-dialog.component";

@Component({
  selector: 'app-admin-clients-detail',
  templateUrl: './admin-client-detail.component.html',
  styleUrls: ['./admin-client-detail.component.scss']
})
export class AdminClientDetailComponent implements OnInit {

  isLoading = true;
  isFormDisabled = false;
  showCampaigns = false;
  showAlternativeEmail = false;
  client: UserModel = null;
  regexEmail: RegExp = new RegExp('[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}');

  constructor(private adminUserSv: AdminClientService,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {

    this.loadClient();

  }

  loadClient() {

    this.isFormDisabled = true;
    this.route.params.subscribe((params: Params) => {
      if (params['clientId']) {
        this.adminUserSv.fetchClient(params['clientId']).subscribe((client: UserModel) => {
          this.client = client;
          this.isFormDisabled = false;
          this.isLoading = false;
        });
      } else {
        this.router.navigate(['admin/clients']);
      }
    });

  }

  onRemoveAlternativeEmail(email: string) {

    _.remove(this.client.alternative_email, (alt_email) => {
      return alt_email === email;
    });

  }

  onShowAlternativeEmail() {

    this.showAlternativeEmail = !this.showAlternativeEmail;

  }

  addAlternativeEmail(form: NgForm) {

    const altEmail = form.value.add_alt_email;
    if (altEmail) {
      this.client.alternative_email = _.union(this.client.alternative_email, [altEmail]);
    }
    this.showAlternativeEmail = false;

  }

  saveClient(form: NgForm) {

    this.showAlternativeEmail = false;
    this.isFormDisabled = true;
    const client = this.client;

    if (form.value.alternative_email) {
      const jsonAltEmail = form.value.alternative_email;
      client.alternative_email = [];
      for (let i in jsonAltEmail) {
        client.alternative_email.push(jsonAltEmail[i]);
      }
    }

    this.adminUserSv.updateClient(client).subscribe(() => {
      this.loadClient();
    });

  }

  campaignsOrClientClicked() {

    this.showCampaigns = !this.showCampaigns;

  }

  changePassword() {
    this.dialog.open(ChangePasswordDialogComponent, {
      minWidth: '20%',
      autoFocus: false
    }).afterClosed().subscribe(event => {
      if (event) {
        if (event.data) {
          this.adminUserSv.changePassword(event.data, this.client.id).subscribe(() => {
            this.loadClient();
          });
        }
      }
    });
  }
}
