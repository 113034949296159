import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserModel} from "../../models/user.model";
import {NgForm} from "@angular/forms";
import {AdminClientService} from "../../services/admin/admin-client.service";
import {StringUtils} from 'src/app/utils/string.utils';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client-dialog.component.html',
  styleUrls: ['./add-client-dialog.component.scss']
})
export class AddClientDialogComponent implements OnInit {

  successName = 'Ajouter un client';
  client: UserModel = new UserModel();
  regexEmail: RegExp = new RegExp('[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}');
  isDisabled = false;
  password = '';
  confirmPassword = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private adminUserSv: AdminClientService,
              private dialogRef: MatDialogRef<AddClientDialogComponent>) {
  }

  ngOnInit(): void {

  }

  stringLength(string: string) {
    return StringUtils.stringLength(string);
  }

  isPasswordMatch(form: NgForm): boolean {
    if (this.password !== this.confirmPassword) {
      form.controls['confirmPassword'].setErrors({'noMatch': true});
      return false;
    }
    return true;
  }

  addClient(f: NgForm) {
    if (f.valid) {
      this.isDisabled = true;
      this.adminUserSv.addClient(this.client, this.password).subscribe(() => {
        this.dialogRef.close({event: 'close', data: true});
      })
    }
  }

}
